import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiNodejsSmall,
  DiDatabase,
  DiPython,
  DiGit,
  DiGithubBadge,
} from "react-icons/di";
import {
  SiCoursera,
  SiCsharp,
  SiJava,
} from "react-icons/si";

import {
  FiMoreHorizontal
} from "react-icons/fi";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiJava/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejsSmall />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCoursera />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDatabase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGithubBadge />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <FiMoreHorizontal />
      </Col>
    </Row>
  );
}

export default Techstack;
