import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Ozbej </span>
            from <span className="purple"> Slovenia.</span>
            <br />I am a student of Computer Sciences and Information Sciences
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing (Video/Board) Games with Friends
            </li>
            <li className="about-activity">
              <ImPointRight /> Creating Content on the Internet
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling and learning Geography
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Life is not going to be easy, unless you make it yourself!"{" "}
          </p>
          <footer className="blockquote-footer">Ozbej</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
