import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./PlaylistCard";
import Particle from "../Particle2";
import magistrski from "../../Assets/Playlists/magistrski.png";
import predmagistrski from "../../Assets/Playlists/predmagistrski.png";
import festival from "../../Assets/Playlists/festival-moskega.jpg";
import letni from "../../Assets/Playlists/letni-koncert.png";
import nasa from "../../Assets/Playlists/nasa-pesem.jpg";
import Divider from "../Divider";
import Form from "../Form";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          VAL <strong className="purple">Playlisti iz Nastopov</strong>
        </h1>
        <p style={{ color: "white" }}>
          Zbrani samo najboljsi videi, ki sem jih zmogel najti za doloceno priloznost.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={magistrski}
              isBlog={false}
              title="Magistrski Koncert Ane Bec"
              description="Ana je za to dobila 10ko tkoda je verjetno vredno poslusat."
              ghLink="https://www.youtube.com/playlist?list=PLrJOG2Kv3ViLOKMlZVndgGxvmadegwwws"
              //demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={predmagistrski}
              isBlog={false}
              title="Predmagistrski Koncert"
              description="Koncert pred Anino bodoco cudovito magistrsko nalogo."
              ghLink="https://www.youtube.com/playlist?list=PLrJOG2Kv3ViK7gOSxtndzrNXKHItYgNPg"
              //demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={festival}
              isBlog={false}
              title="Festival Moskega Petja"
              description="Decki se malo pozabavajo na festivalu moskega petja v Vipavskem Krizu, kjer se jim pripeti neverjetna nezgoda."
              ghLink="https://www.youtube.com/playlist?list=PLrJOG2Kv3ViKV75hbUMHf8gyYBBXPGNmH"
              //demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={letni}
              isBlog={false}
              title="Letni Koncert 2022"
              description="Mnogo pesmi, ki se jih je folk naucil cez tist let."
              ghLink="https://www.youtube.com/playlist?list=PLrJOG2Kv3ViLDeFGsOvKQfNF5eQA_-n9Q"
              //demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nasa}
              isBlog={false}
              title="Nasa Pesem 2022"
              description="Mi ne tekmujemo ampak bi rabl. Amen."
              ghLink="https://www.youtube.com/playlist?list=PLrJOG2Kv3ViIUprWu2jKICWIqw6kKVpO6"
              //demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
