import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Content Creation </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are my channels I've worked on  or will be.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Ozbae"
              description="YouTube channel for english content. Still am deciding whether I want to switch to IRL content or stay the way it is. "
              ghLink="https://www.youtube.com/channel/UCMFIJgrR6PlJZ5r7m9Fxl8A"
              //demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Ozbej"
              description="YouTube channel for slovenian content. Will be easier to work on, since it is my first language and can come up with more jokes."
              ghLink="https://www.youtube.com/channel/UCSBTVJWisCdO_v2f6GvcFzw"
              //demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              isTwitch={true}
              title="OzBae_"
              description="Twitch channel for streaming videogames and have fun with community in chat. Schedule soon to be published."
              ghLink="https://www.twitch.com/OzBae_"
              //demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="FRIxBAE"
              description="YouTube channel for shitpost content for my local Faculty of Computer Sciences and Information Sciences. Basically entertaining other students on the UNI."
              ghLink="https://www.youtube.com/channel/UCUL04DiS2clQSKxttMgrRaA"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
