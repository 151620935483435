import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "a Web Developer",
          "a Game Developer",
          "a Graphic Designer",
          "a Content Creator",
          "a Freetime Gamer",
          "a Friend.",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
